<template>
  <div
    id="question"
    class="mb-16"
  >
    <div>
      <div class="card-content">
        <div class="right d-flex">
          <question-tag
            v-for="tag in tags"
            :key="tag.id"
            :tag="tag"
            small
            class="mr-2"
          />

          <out-of-time-student-chip
            v-if="correctionMode && showScore"
            :answer="answer"
            :current-user="currentUser"
            class="mr-2"
          />

          <chip-points
            v-if="question.isAnswerable"
            :coefficient="coefficient"
            :has-answer="!!answer"
            :hide-score="previewMode || !correctionMode || !showScore"
            :is-skipped="answer && answer.skipped"
            :score="answerScore"
            :pending="answer && answer.status === 'pending'"
            small
          />
        </div>

        <div
          v-if="showTitle"
          @click.alt.exact.stop="toggleDebug()"
        >
          <div class="question-subtitle grey--text text--lighten-1 mb-2">
            {{ subtitle }}
            <answer-debug-info
              v-if="debug && answer"
              :answer="answer"
            />
          </div>

          <katex-md
            v-if="question"
            :expr="question.attributes.name"
            class="card-title question-title flow-text"
          />
        </div>

        <out-of-time-alert
          v-if="quizInstance"
          :answer="answer"
          :quiz-instance="quizInstance"
          :current-user="currentUser"
          :quiz="question"
          :score="quizScore"
        />

        <question-choices
          v-if="question.attributes.type === 'mcq'"
          v-model="selectedChoices"
          :choices="choices"
          :correction-mode="statusCorrection"
          :multi-choices="question.allowMultipleChoices"
          :preview-mode="previewMode"
          class="mb-4 mt-4"
          @change="localSaveAnswer"
          :errors="errors"
        />

        <v-card
          flat
          outlined
          v-if="question.attributes.type === 'spreadsheet'"
          class="mt-4 mb-4"
        >
          <spread-sheet
            :data.sync="answerSpreadsheetContent"
            @change="localSaveAnswer"
            :readonly="correctionMode"
            :hide-menus="correctionMode"
            :update-on-data-changes="previewMode"
            :emit-changes="!previewMode"
          />
        </v-card>

        <div
          v-if="question.attributes.type === 'open'"
          class="mt-4 mb-4"
        >
          <open-question-field
            v-model="answerContent"
            :limited-content="question.attributes.limited_content"
            :limit-count="question.attributes.limit_count"
            :limit-type="question.attributes.limit_type"
            :answerable="answerable"
            :force-show-preview="correctionMode"
            :show-input-field="!correctionMode"
            :show-preview-label="!correctionMode"
            @input="localSaveAnswer"
            :rich-text="quiz?.rich_text_answers"
          >
            <template #bellowPreview>
              <answer-uploads-preview
                v-if="answer && correctionMode"
                :answer="answer"
                :correction-mode="correctionMode"
                class="mx-2"
                dense
              />
            </template>
          </open-question-field>


          <div v-if="question.attributes.allow_attachment">
            <answer-file-upload
              class="mt-2"
              v-if="!correctionMode"
              :disabled="previewMode"
              :upload-url="api.activeStorageDirectUploadUrl()"
              @upload="uploadImage"
              :answer="answer"
              :question="question"
            >
              <template #buttons>
                <answer-upload-qr-code
                  v-if="!correctionMode"
                  :answer-content="answerContent"
                  :disabled="previewMode"
                  :question="question"
                  :quiz="quiz"
                  :quizzes-attempt-summary="quizzesAttemptSummary"
                  @saveAnswer="saveAnswer"
                />
              </template>
            </answer-file-upload>
          </div>
        </div>

        <slot
          v-if="manualCorrectionMode && answer?.manually_gradable"
          :answer="answer"
          :question="question"
          name="grader"
        />

        <div
          v-if="showComment"
          class="comment"
        >
          <question-correction-comment
            v-if="hasQuestionComment"
            :content="questionComment"
          />
          <question-correction-comment
            v-if="hasAnswerComment"
            :content="answer.comment"
            answer-comment
          />
        </div>

        <template v-if="!previewMode">
          <slot name="buttons" />
        </template>
      </div>
    </div>
    <div v-if="showWaitOtherStudentMessage">
      <h5 class="center-align">
        {{ $t("live.waitForOthers") }}
      </h5>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import KatexMD from "../katex-md.vue"
import OutOfTimeAlert from "./out_of_time_alert.vue"
import OutOfTimeStudentChip from "./out_of_time_student_chip.vue"
import OpenQuestionField from "./open_question_field.vue"
import api from "../../api"
import AnswerUploadsPreview from "./answer_uploads_preview.vue"
import AnswerUploadQrCode from "./answer_upload_qr_code.vue"
import QuestionChoices from "./question_choices.vue"
import ChipPoints from "./chip_points.vue"
import QuestionTag from "../quiz/editor/QuestionTag.vue"
import AnswerFileUpload from "./answer_file_upload.vue"
import textExpenderDetector from "./textExpanderDetector"
import QuestionCorrectionComment from "./question_correction_comment.vue"
import AnswerDebugInfo from "@/components/questions/answer_debug_info.vue"
import SpreadSheet from "@/components/questions/SpreadSheet.vue"
import { Answer } from "@/models/answerModel"

export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
    quizInstance: {
      type: Object,
      default: null,
    },
    userId: { type: Number, default: null },
    quiz: { type: Object, required: true },
    correctionMode: { type: Boolean, default: null },
    manualCorrectionMode: { type: Boolean, default: null },
    forceHideComment: { type: Boolean, default: false },
    index: { type: Number, default: null },
    allowAnswer: { type: Boolean, default: true },
    showScore: { type: Boolean, default: true },
    allowNavigation: { type: Boolean, default: false },
    previewMode: { type: Boolean, default: false },
    showTitle: { type: Boolean, default: true },
    questionsCount: { type: Number, default: null },
    exercise: { type: Object, default: null },
    quizScore: { type: Object, default: null },
    quizzesAttemptSummary: { type: Object, default: null },
  },
  components: {
    SpreadSheet,
    AnswerDebugInfo,
    QuestionCorrectionComment,
    AnswerFileUpload,
    ChipPoints,
    QuestionChoices,
    AnswerUploadQrCode,
    AnswerUploadsPreview,
    OpenQuestionField,
    OutOfTimeStudentChip,
    OutOfTimeAlert,
    QuestionTag,
    "katex-md": KatexMD,
  },
  mixins: [textExpenderDetector],
  data: function () {
    return {
      selectedChoices: [],
      answerContent: "",
      answerSpreadsheetContent: {},
      showSkipDialog: false,
      debug: false,
    }
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "answerByQuestionAndUserId",
      "questionById",
      "questionsTags",
      "questionsTagsCategoryByQuizId",
    ]),
    api() {
      return api
    },
    customUserId() {
      return this.userId || this.currentUser?.id
    },
    answerable() {
      if (this.statusCorrection) return false
      return !this.previewMode
    },
    answer() {
      return this.answerByQuestionAndUserId(
        this.question?.id,
        this.customUserId
      )
    },
    answerScore() {
      return this.answer?.score
    },
    tags() {
      let visibleCategoriesIds = this.questionsTagsCategoryByQuizId(
        this.quiz?.id
      )
        .filter((category) => category.visible_to_students == true)
        .map((category) => category.id)

      return this.questionsTags.filter(
        (tag) =>
          this.question?.attributes?.tag_uuids?.includes(tag.uuid) &&
          visibleCategoriesIds.includes(tag.category_id)
      )
    },
    alreadyAnswered: function () {
      return Answer(this.answer).isAnswered
    },
    statusCorrection() {
      return this.correctionMode === true
    },
    showWaitOtherStudentMessage() {
      if (this.questionable?.quiz_type === "live" && !this.correctionMode) {
        return this.alreadyAnswered
      }
    },
    questionComment() {
      return (
        this.questionWithCorrection?.comment ||
        this.question?.attributes?.comment
      )
    },
    hasQuestionComment() {
      if (!this.questionComment) return false
      return this.questionComment?.trim() !== ""
    },
    hasAnswerComment() {
      if (!this.answer?.comment) return false
      return this.answer?.comment?.trim() !== ""
    },
    showComment() {
      if (this.forceHideComment) return false
      if (!this.hasQuestionComment && !this.hasAnswerComment) return false

      return this.statusCorrection
    },
    position() {
      return this.index + 1 || this.question?.attributes?.position + 1
    },
    _questionCount() {
      return (
        this.questionsCount ||
        this.questionable?.questions_count ||
        this.questionable?.questions?.length ||
        1
      )
    },
    subtitle() {
      return [
        this.question.subtitle(this.position, this._questionCount),
        this.question.subtitleInstructions({
          withCount: this.questionable?.answers_selection_limit,
        }),
      ]
        .filter(Boolean)
        .join(" - ")
    },
    questionWithCorrection() {
      return this.questionById(this.question.id)
    },
    orderedChoicesWithCorrection() {
      if (!this.questionWithCorrection) return null
      return this.question.attributes?.choices?.map((c) => {
        return this.questionWithCorrection?.choices?.find(
          (c2) => c2.id === c.id
        )
      })
    },
    choices() {
      // TODO: replace this by a function on quizNode model or on quizDraft model
      return (
        this.orderedChoicesWithCorrection ||
        this.question.attributes.choices ||
        this.question.children
      )
    },
    coefficient() {
      if(this.questionWithCorrection) return this.questionWithCorrection.coefficient

      return  this.question.attributes.coefficient
    },

    overMaximumSelection() {
      if (!this.questionable?.answers_selection_limit) return false
      return this.selectedChoices.length > this.question.correctChoicesCount()
    },

    errors() {
      let errors = []
      if (this.overMaximumSelection) {
        errors.push(
          this.$t("live.errors.overMaximumSelection", {
            maximum: this.question.correctChoicesCount(),
          })
        )
      }
      return errors
    },
  },
  methods: {
    ...mapActions(["createAnswer"]),
    setDefaultAnswer() {
      this.selectedChoices = this.answer?.choices_ids?.slice() || []
      this.answerContent = this.answer?.content || ""

      // Init with template if there is no answer yet
      if(this.answer && ![null, {}].includes(this.answer?.spreadsheet_content) ) {
        this.answerSpreadsheetContent = this.answer?.spreadsheet_content
      } else {
        this.answerSpreadsheetContent = this.question.attributes.spreadsheet_template || {}
      }
    },
    localSaveAnswer() {
      if (this.previewMode) return

      this.createAnswer({
        quizzesAttemptSummary: this.quizzesAttemptSummary,
        question: this.question,
        choices_ids: this.selectedChoices,
        content: this.answerContent,
        spreadsheetContent: this.answerSpreadsheetContent,
      })
    },
    saveAnswer() {
      this.createAnswer({
        quizzesAttemptSummary: this.quizzesAttemptSummary,
        question: this.question,
        choices_ids: this.selectedChoices,
        content: this.answerContent,
        spreadsheetContent: this.answerSpreadsheetContent,
        save: true,
      })
      this.$emit("questionAnswered")

      if (!this.questionable.correction_btw_questions) {
        this.$emit("nextQuestion")
      }
    },
    uploadImage({ blob }) {
      this.createAnswer({
        quizzesAttemptSummary: this.quizzesAttemptSummary,
        question: this.question,
        choices_ids: this.selectedChoices,
        content: this.answerContent,
        spreadsheetContent: this.answerSpreadsheetContent,
        picture: blob.signed_id,
        save: true,
      })
    },
    toggleDebug() {
      this.debug = !this.debug
    },
  },
  watch: {
    "question.id": function () {
      this.selectedChoices = []
      this.answerContent = ""
      this.answerSpreadsheetContent = this.question.attributes.spreadsheet_template || {}
    },
    answer: {
      handler() {
        this.setDefaultAnswer()
      },
      immediate: true,
    },
    question: {
      handler() {
        if(this.previewMode) this.setDefaultAnswer()
      },
      immediate: true,
      deep: true,
    },
    answerContent(newValue, oldValue) {
      this.detectBulkInsertion(newValue, oldValue)
      this.detectFastInsert(newValue)
    },
  },
}
</script>

<style scoped>
.question-title {
  background: transparent;
}
</style>

<style>
.full-screen-teacher .choice,
#live_quiz_teacher .choice,
#question-teacher .choice {
  background-color: #ddd;
  box-shadow: none;
  margin: 0;
}

.full-screen-teacher .choice-index {
  background: #3778ff !important;
}

.question-title p {
  margin-bottom: 0;
}

.question-title {
  font-size: 16px;
  font-weight: 400;
  color: rgb(0 0 0 / 70%);
}

.question-subtitle {
  font-size: 0.85em;
  font-weight: 500;
}

.comment {
  padding-bottom: 1em;
}

code.hljs {
  background: #f0f0f0 !important;
}
</style>
