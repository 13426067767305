<template>
  <v-alert
    dense
    :icon="answerComment ? 'mdi-account' : 'check'"
    text
  >
    <katex-md :expr="content" />
  </v-alert>
</template>
<script>

import KatexMD from "../katex-md.vue"

export default {
  name: "QuestionCorrectionComment",
  components: { "katex-md": KatexMD },
  props: {
    content: { type: String, default: "" },
    answerComment: { type: Boolean, default: false },
  },
}
</script>
